import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-comman-for-search',
  templateUrl: './comman-for-search.component.html',
  styleUrls: ['./comman-for-search.component.scss'],
})
export class CommanForSearchComponent implements OnInit {
  form: FormGroup;  

  constructor(
    private formBuilder: FormBuilder, 
     public modalCtrl: ModalController, 
  ) { 
    this.form = this.formBuilder.group({
      Search: [null],
      menuslist:[''],
      startDate: [null],
      endDate: [null],
      keywords: [],
    });
  }

  ngOnInit() {}

  onSubmit(){

  }

}
