import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CategoryService } from 'src/app/shared/services/api/category.service';

@Component({
  selector: 'app-filter-item-status',
  templateUrl: './filter-item-status.component.html',
  styleUrls: ['./filter-item-status.component.scss'],
})
export class FilterItemStatusComponent implements OnInit {
@Input()ScreenType: string;
inprocress: FormGroup; 
History: FormGroup; 
providersliat:any=[]
AllRestaurantsliat:any=[]
screenview:string=''
  constructor(
    private readonly modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private categoryService: CategoryService,
  ) { 

    this.inprocress = this.formBuilder.group({
      Search: [null],
      startDate: [null],
      endDate: [null],
      status: [null],
      Delivarydays:[null],
      ProviderName:[null],
      Restarants:[null],
    });
    this.History = this.formBuilder.group({
      Search: [null],
      startDate: [null],
      endDate: [null],
      status: [null],
      ProviderName:[null],
      Restarants:[null],

    });
  }

  ngOnInit() {
    this.screenview=this.ScreenType
    console.log(this.ScreenType)
    this.getAllproviders()
    this.FindAllRestaurants()
  }

  getAllproviders(){
   this.categoryService.FindAllProviders().subscribe((result:any)=>{
     if(result){
    this.providersliat=result
     }
   })
  }
  FindAllRestaurants(){
    this.categoryService.FindAllRestaurants().subscribe((result:any)=>{
      if(result){
     this.AllRestaurantsliat=result
      }
    })
  }

  onSubmit(){
    if(this.screenview=='inprocress-R'||this.screenview=='inprocress-P'){
     this.modalCtrl.dismiss(this.inprocress.value)
    }else{
      this.modalCtrl.dismiss(this.History.value)
    }
    
  }



  closepopup(){
    this.modalCtrl.dismiss()
  }

}
