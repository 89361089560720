import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { GoogleAuthRedirectComponent } from './pages/auth/google-auth-redirect/google-auth-redirect.component';
import { MicrosoftAuthRedirectComponent } from './pages/auth/microsoft-auth-redirect/microsoft-auth-redirect.component';

const routes: Routes = [
  // website 0000000000
  // {
  //   path: '',
  //   redirectTo: 'auth',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  // },
  // Guided Visite
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/Guided_Visite/guided-visite/guided-visite.module').then(m => m.GuidedVisiteModule)
  },
  // for All 
  {
    path: 'register', 
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'food-mgmt',
    data: { roles: ['Role.Admin', 'Role.Moderator'] },
    loadChildren: () => import('./pages/food-management/food-management.module').then(m => m.FoodManagementPageModule)
  },
  {
    path: 'res-mgmt',
    loadChildren: () => import('./pages/resource-management/resource-management.module').then(m => m.ResourceManagementPageModule)
  },
  {
    path: 'planning',
    loadChildren: () => import('./pages/planning/planning.module').then(m => m.PlanningPageModule)
  },
  {
    path: 'table-mgmt',
    loadChildren: () => import('./pages/table-management/table-management.module').then(m => m.TableManagementPageModule)
  },

  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'restaurant-mgmt',
    loadChildren: () => import('./pages/restaurant-management/restaurant-management.module').then( m => m.RestaurantManagementPageModule)
  },
  {
    path: 'provider-restaurant-mgmt',
    loadChildren: () => import('./pages/provider-restaturant-mgmt/provider-restaurant-mgmt.module').then( m => m.ProviderRestaurantMgmtModule)
  },
  {
    path: 'provider-res-mgmt',
    loadChildren: () => import('./pages/provider-resource-management/provider-resource.module').then( m => m.ProviderResourceModule)
  },
  {
    path: 'supplier-mgmt',
    loadChildren: () => import('./pages/suppliers-management/suppliers-management.module').then( m => m.SuppliersManagementPageModule)
  },
  {
    path: 'provider-supplier-mgmt',
    loadChildren: () => import('./pages/provider-supplier-mgmt/provider-supplier-mgmt.module').then( m => m.ProviderSupplierMgmtModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'floorplan',
    loadChildren: () => import('./pages/floor-plan/floor-plan.module').then( m => m.FloorPlanModule)
  },
  {
    path: 'floortablemanagement',
    loadChildren: () => import('./pages/floor-table-management/floor-table-management.module').then( m => m.FloorTableManagementModule)
  },
  
  {
    path: 'org-manage',
    loadChildren: () => import('./pages/broker/organization-manage/organization-manage.module').then( m => m.OrganizationManageModule)
  },
  {
    path: 'broker-manage',
    loadChildren: () => import('./pages/broker/broker-manage/broker-manage.module').then( m => m.BrokerManageModule)
  },

  {
    path: 'Resource-Management',
    loadChildren: () => import('./pages/broker/resource-management/resource-management.module').then( m => m.ResourceManagementModule)
  },


  

  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
