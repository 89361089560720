import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PayService } from 'src/app/shared/services/api/pay.service';
import { PdfService } from 'src/app/shared/services/api/pdf.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MessagesService } from '../../auth/Guided_Visite/messages.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss']
 
})
export class GenerateBillComponent implements OnInit {
  @Input() data: any;
  @Input() customerdetails: any;
  bill: any; 
  isLoaded: boolean = true;
  disable: boolean = true;
  constructor(
    private payService: PayService,
    private modalCtrl: ModalController,
    private pdfService: PdfService,
    public guidedvisitemessage:MessagesService,
    private messageService: MessageService,
    ) { }

    closepopup(){
      this.modalCtrl.dismiss()
    }

  ngOnInit() {
    console.log(this.data)
    this.customerdetails=this.data.customerNames
    this.payService.generateBill(this.data).subscribe((res:any) => {
      this.bill = res;
      this.disable = false;
      console.log(this.bill);
      this.bill['tableName'] = this.bill.tableNames!=null? this.bill.tableNames[0]: '',
      this.bill['trasnactionTotal'] = [];
      let obj= {
        name: 'Total Euro',
        amount: this.bill.totalEuro
      }
      this.bill['trasnactionTotal'].push(obj);
      let obj1= {
        name: 'Total HT',
        amount: this.bill.totalHT
      }
      this.bill['trasnactionTotal'].push(obj1);
      let obj2= {
        name: 'Total TVA 10%',
        amount: this.bill.totalTVA
      }
      this.bill['trasnactionTotal'].push(obj2);
      let obj3= {
        name: 'Carte Credit',
        amount: this.bill.creditCard
      }
      this.bill['trasnactionTotal'].push(obj3);
      console.log(this.bill)
      this.isLoaded = false;
    },  err => {
      this.isLoaded = false;
    })
  }

  getdiscountvalue(Discount,price){
    const finalvalue=price*(Discount/100)
    return finalvalue
  }


  print() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Download','This button allows you to Download Invoice')
      }else{
      this.pdfService.createPdfService(this.bill,'Bill');
      }
  }

  SendToEmail(){
    const formData = new FormData();
    const file=this.pdfService.createPdfService(this.bill,'SendToEmail');
    formData?.append('file',file);
    this.payService.SendBillToEmail(this.customerdetails[0].id,formData).subscribe((result)=>{
      if(result){
        this.messageService.presentToast('Bill successfully sent to the customer email');
        console.log(result)
      }
    })
  }
}
