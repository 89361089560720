import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user.model';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) { }

    getAllUsersService(): Observable<User[]> {
        const params = new HttpParams()
            .append('NumberOfRecord', '0')
            .append('PageNumber', '1')
            .append('Search', '')
            .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<User[]>(api + '/api/User/FindUserBySearch', { params })
            .pipe(
                map(response => response["data"])
            );
    }
    deletetag(id: number) {
        return this.http.delete(api + '/api/Tags/DeleteTag?Id=' + id);
      }
      PermanentDeleteTag(id:number){
        return this.http.delete(api + '/api/Tags/PermanentDeleteTag?Id=' + id);

      }
    getAllUsers(NumberOfRecord,PageNumber,Search): Observable<User[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));

        const params = new HttpParams()
            .append('NumberOfRecord', NumberOfRecord)
            .append('PageNumber', PageNumber)
            .append('Search', Search)
            .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<User[]>(api + '/api/User/FindUserBySearch', { params })
            .pipe(
                map(response => response["data"])
            );
    }

    GetBrokerOrganisationUsers(): Observable<User[]> {
        let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
        return this.http.get<User[]>(api + '/api/Accounts/GetBrokerOrganisationUsers?brokerOrganisationId='+brokerOrganisationId)
    }

    GetBrokerOrganisationDetails(): Observable<User[]> {
        let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
        return this.http.get<User[]>(api + '/api/Accounts/GetBrokerOrganisationDetails?brokerOrganisationId='+brokerOrganisationId)
    }
    GetFindAllBrokerCards(): Observable<any> {
        let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
        return this.http.get<User[]>(api + '/api/BankAccount/FindAllBrokerCards?brokerOrgId='+brokerOrganisationId)
    }

    GetAllManagersAndSuperManagersForUser(){
        let userId = sessionStorage.getItem('userId');
        return this.http.get<User[]>(api + '/api/User/GetAllManagersAndSuperManagersForUser?userId='+userId,)

    }
    
    getUserByIdService(id: string): Observable<User> {
        return this.http.get<User>(api + '/api/User/FindUserById?userId=' + id);
    }

    gettabledetails(TableId:string){
        return this.http.get(api+'/api/TableManagements/GetTableDetailsById?TableId='+TableId)
    }

    FindTagColumns(TableName: string): Observable<User> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<User>(api + '/api/Tags/FindTagColumns?TableName=' + TableName+'&restaurantId='+restaurantId);
    }

    addtagcolum(TableName,data){
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post(api + '/api/Tags/AddTagColumns?restaurantId=' + restaurantId+'&TableName='+TableName,data).toPromise()
    }

    addUserService(formData: FormData): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post(api + '/api/User/CreateUser?RestaurantId=' + restaurantId, formData);
    }
    addBrokerUserService(formData: FormData): Observable<any> {
        let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
        return this.http.post(api + '/api/Accounts/CreateBrokerUser?brokerOrganisationId=' + brokerOrganisationId, formData);
    } 
    UpdateBrokerUserService(formData: FormData): Observable<any> {
        let userid = 2;
        return this.http.post(api + '/api/Accounts/UpdateBrokerUser?userid=' + userid, formData);
    }

    updateUserService(userid,formData: any) {
        let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
        if(brokerOrganisationId){
            return this.http.put(api + '/api/Accounts/UpdateBrokerUser?userid'+userid, formData);
        }else{
        return this.http.put(api + '/api/User/UpdateUser', formData);
        }
    }

    deleteUserService(id: string): Observable<any> {
        return this.http.delete(api + '/api/User/DeleteUserPermanantly?Id=' + id);
    }

    getAllCooksService(): Observable<any[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/User/FindAllCooks?RestaurantId=' + restaurantId);
    }
    deleteUserProfile(MediaItemId: number): Observable<any> {
        return this.http.delete(api + '/api/User/RemoveUserProfile?MediaItemId=' + MediaItemId);
    }
    updateUserProfile(profile:FormData, mediaItemId: number): Observable<any> {

        return this.http.put(api + '/api/User/UpdateUserProfile?MediaItemId=' + mediaItemId , profile);
    }
    addProfile(profile: FormData, userId: string,mediaItemId:number): Observable<any> {
        return this.http.post(api + '/api/User/UploadUserProfile?UserId=' + userId+'&MediaItemId='+mediaItemId, profile);
    }
    updateUserMail(user: any): Observable<any> {
        return this.http.put(api + '/api/User/UpdateEmail' , user);
    }
    
}