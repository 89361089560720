import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { SignalRService } from 'src/app/shared/services/api/signal-rservice.service';
import { ProfilePickFormatService } from 'src/app/shared/services/profile-pick-format.service';
import { Level3ChartShowComponent } from '../level3-chart-show/level3-chart-show.component';
import { Level2ChartShowComponent } from '../level2-chart-show/level2-chart-show.component';

@Component({
  selector: 'app-customer-crm-ui',
  templateUrl: './customer-crm-ui.component.html',
  styleUrls: ['./customer-crm-ui.component.scss'],
})
export class CustomerCRMUIComponent implements OnInit {
  @Input() items
  Customersdetails;
  showIngredientslist=false
  date=new Date()
  loadder=true
  constructor(
    private readonly modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    private signalRService: SignalRService,
    private changeDetectorRefs: ChangeDetectorRef,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private profilepickformat: ProfilePickFormatService,
  ) { }

  ngOnInit() {
    console.log(this.items)
    this.getCustomerCRMById()
  }
  getuserFLnames(name) {
    return this.profilepickformat.splitcustomerName(name);
  }

  getColor(index) {
    return this.profilepickformat.getColor(index);
  }


closepopup(){
  this.modalCtrl.dismiss()
}
  getCustomerCRMById() {
    this.loadder=true
    this.restaurantService.getCRMCustomersByID(this.items[0].id?this.items[0].id:this.items[0].customerId).subscribe((result: any) => {
      if (result) {
        this.loadder=false
        console.log(result)
        console.log(this.items)
        this.Customersdetails = result
        this.Customersdetails.profilePicture=null
        this.Customersdetails.name=this.items[0]?.clientName?this.items[0]?.clientName:this.items[0]?.name
        this.Customersdetails.customerId=this.items[0]?.id
        this.restaurantService.FindFavoriteItems(this.items[0].id?this.items[0].id:this.items[0].customerId).subscribe((result)=>{
          if(result){
            this.Customersdetails.FindFavoriteItems=result
          }
        })
        this.restaurantService.FindLoyaltyPrograms(this.items[0].id?this.items[0].id:this.items[0].customerId).subscribe((result)=>{
          if(result){
            this.Customersdetails.FindLoyaltyPrograms=result
          }
        })
        this.restaurantService.FindExludeIngredients(this.items[0].id?this.items[0].id:this.items[0].customerId).subscribe((result)=>{
          if(result){
            this.Customersdetails.FindExludeIngredients=result
          }
        })
        
      }
    })
    setTimeout(() => {
      console.log(this.Customersdetails)
    }, 10);
  }
  assignCssClass(progress: number) {
    let cssClass = '';
    if (progress >= 0 && progress <= 20) {
      cssClass = 'color-20';
    } else if (progress >= 21 && progress <= 50) {
      cssClass = 'color-50';
    } else if (progress >= 51 && progress <= 80) {
      cssClass = 'color-80';
    } else if (progress >= 81 && progress <= 90) {
      cssClass = 'color-90';
    } else if (progress >= 100) {
      cssClass = 'color-100';
    }
    return cssClass;
  }

  getLastvisit(data,type){
    this.leveltwochart(data,type)
  }
  getTotalVisits(data,type){
    this.leveltwochart(data,type)
  }

  getOnlineOrders(data,type){
    this.leveltwochart(data,type)
  }

    // leveltwochart 
    async leveltwochart(value: any,type) {
      value.clientdetails=this.items
      const popover = await this.modalCtrl.create({
        component: Level2ChartShowComponent,
        componentProps: {
          data: {
            data: value,
            charttype: type,
          },
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data) {
        console.log(data);
      }
    }




  listofexcludeIngredientLists(){
    this.showIngredientslist=!this.showIngredientslist
  }

  loginformat(value){
    return value
  }




  async mapCofOption() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Export',
          icon: 'bookmark',
          cssClass: 'blue left-align-buttons',
          handler: () => {
          },
        },
      ],
    });
    await actionSheet.present();
  }

}
