export const environment = {
  production: true,

 // google_api_Key 
 google_api_Key: 'AIzaSyC6aP1kQgXDXbroJouE110syf0W9wVjIUc',
 // DeveloperKey 
 DeveloperKey: 'AIzaSyD1azakHWpC9tK_HkDHz_LyMUx0Bpn0AQE',
 // googleclientid
 googleclientid: '710649339803-igsv3d817defv3r1aje0f3u1ehpjuqq0.apps.googleusercontent.com',
 // mircosoftclintId 
 mircosoftclintId:'127ec4ed-df18-44d9-81a0-75c24e3b6d4e',

  // local test
  baseUrl: 'https://demo.swagger.leanresto.com',
  stripe_key: 'pk_test_NvHfZByEOwQXHrlPIJsASUVt00vfYBRGrW', 
  gooleredirectUri: 'http://localhost:8100/auth/GoogleAuth',
  MicrosoftredirectUri: 'http://localhost:8100/auth/MicrosoftAuth',


  // live 
  // baseUrl: 'https://www.swagger.leanresto.com',
  // stripe_key: 'pk_test_NvHfZByEOwQXHrlPIJsASUVt00vfYBRGrW', 
  // gooleredirectUri: 'https://app.leanresto.com/auth/GoogleAuth',
  // MicrosoftredirectUri: 'https://app.leanresto.com/auth/MicrosoftAuth',
};
