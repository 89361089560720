import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfilePickFormatService {

  constructor() { }

splitcustomerName(name){
  // console.log(name)
  var data=name.split(' ')
  // console.log(data)
  var v1=data[0][0]
  var v2=data[1][0]
   const value= v1+v2
   return value.toUpperCase()
}

  getuserFLnames(value1,value2){
    var v1=value1[0]
    var v2=value2[0]
     const value= v1+v2
     return value.toUpperCase()
  }
  getColor(index) {
    // console.log(index)
    const colors = [];
  
    for (let i = 0; i <50; i++) {
      // Generate hue, saturation, and lightness values
      const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360
      const saturation = Math.floor(Math.random() * 30) + 50; // Random saturation between 50 and 80
      const lightness = Math.floor(Math.random() * 20) + 10; // Random lightness between 10 and 30
      
      // Create HSL color
      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      
      // Push color to the array
      colors.push(color);
    }
    return colors[index];
  }
}
