import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Restaurant } from '../../models/restaurant.model';

const api = environment.baseUrl;

@Injectable({
    providedIn: 'root'
}) 
export class RestaurantService {
    constructor(private http: HttpClient) { }

    getAllTVAService(): Observable<any[]> {
        let restaurantId = Number(localStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Restuarant/FindTVA?RestaurantId=' + restaurantId);
    }

    getAllRestuarants(restaurantId: any): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/Restuarant/FindMyRestaurant?RestaurantId=' + parseInt(restaurantId));
    }
    FindMyAllRestaurants(UserId: string): Observable<any> {
        return this.http.get<any>(api + '/api/Restuarant/FindMyAllRestaurants?UserId=' + UserId);
    }

    GetProvidersOrganisations(UserId: string): Observable<any> {
        return this.http.get<any>(api + '/api/Auth/GetProvidersOrganisations?UserId=' + UserId);
      }
    getBrokerOrganization(UserId: string): Observable<any> {
        return this.http.get<any>(api + '/api/Accounts/FindAllBrokerOrganisations?UserId=' + UserId);
      }

    GetRestaurantsByprivider(): Observable<any> {
        return this.http.get<any>(api + '/api/OfferBucket/FindAllRestaurantByProvider');
    }

    GetAllLoyalityPrograms(isVisit): Observable<any> {
        return this.http.get<any>(api + '/api/AllClientOffer/GetAllLoyalityPrograms?isVisit='+isVisit);
    }

    FindAllWaiters(): Observable<any> {
        let restaurantId = Number(localStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/User/FindAllWaiters?RestaurantId='+restaurantId);
    }

    GetAllCountryNames(): Observable<any> {
        return this.http.get<any>(api + '/api/User/GetAllCountryNames');
    }
    GetTimeZoneOFTheCountryCode(CountryCode): Observable<any> {
        return this.http.get<any>(api + '/api/User/GetTimeZoneOFTheCountryCode?CountryCode='+CountryCode);
    }

    updateRestaurant(restaurant: Restaurant): Observable<any> {
        return this.http.put(api + '/api/Restuarant/Update', restaurant);
    }

   createRestaurant(restaurant: Restaurant): Observable<any> {
        return this.http.post(api + '/api/Restuarant/AddRestaurant', restaurant);
    }

    CreateLoyalityProgram(data): Observable<any> {
        return this.http.post(api + '/api/AllClientOffer/CreateLoyalityProgram',data );
    }

    UpdateRestaurantTimeZone(details): Observable<any> {
        let restaurantId = Number(localStorage.getItem('resturantId'));
        return this.http.put(api + '/api/Restuarant/UpdateRestaurantTimeZone?restaurantId='+restaurantId, details);
    }

    SetWaiterDiscountAuthorization(waiterId,canApplyDiscount): Observable<any> {
        let restaurantId = Number(localStorage.getItem('resturantId'));
        return this.http.put(api + '/api/User/SetWaiterDiscountAuthorization?restaurantId='+restaurantId+'&waiterId='+waiterId+'&canApplyDiscount='+canApplyDiscount, {});
    }

    getAllInvoice(): Observable<any[]> {
        let restaurantId = Number(localStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Invoice/FindAllInvoice?RestaurantId=' + restaurantId);
    }
    VerifyPassword(password:any) {
        console.log(password)
        return this.http.post<any[]>(api + '/api/Auth/VerifyPassword' ,{password});
    }

    FindAllBrokerInvoice(): Observable<any[]> {
        let brokerOrganisationId = localStorage.getItem('brokerOrganisationId'); 
        return this.http.get<any[]>(api + '/api/Invoice/FindAllBrokerInvoice?BrokerOrgId=' + brokerOrganisationId);
    }

    deleteProfile(MediaItemId: number, restaurantId: number): Observable<any> {
        return this.http.delete(api + '/api/Restuarant/RemoveLogo?RestaurantId=' + restaurantId + '&MediaItemId=' + MediaItemId);
    }
    updateProfile(file: FormData, mediaItemId: number, restaurantId: number): Observable<any> {
        return this.http.put(api + '/api/Restuarant/UploadLogo?RestaurantId=' + restaurantId + '&MediaItemId=' + mediaItemId, file);
    }
    deleteRestaruant(id: string): Observable<any> {
        return this.http.delete(api + '/api/Restuarant/Delete?Id=' + id);
    }
//    addCategoryProfile(profile: FormData, categoryId: number): Observable<any> {
//         return this.http.post(api + '/api/Category/UploadProfile?CategoryId=' + categoryId, profile);
//     }

getCRMCustomers(report,PageNumber,NumberOfRecord){
    console.log(report)
    return this.http.get<any[]>(api + '/api/Customer/CustomersCRM?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId+'&PageNumber='+PageNumber+'&PageSize='+NumberOfRecord); 
}
GenerateRevenueReportDetails(report,PageNumber,NumberOfRecord){
    console.log(report)
    return this.http.get<any[]>(api + '/api/Report/GenerateRevenueReportDetails?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId+'&PageNumber='+PageNumber+'&PageSize='+NumberOfRecord); 
}



GenerateOrderReportDetails(report){
    console.log(report)
    return this.http.get<any[]>(api + '/api/Report/GenerateOrderReportDetails?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId); 
}
GenerateOrderReportDates(report,PageNumber,NumberOfRecord){
    console.log(report)
    return this.http.get<any[]>(api + '/api/Report/GenerateOrderReportDates?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId+'&PageNumber='+PageNumber+'&PageSize='+NumberOfRecord); 
}
FindCustomerItems(CustomerName){
    return this.http.get<any[]>(api + '/api/Order/FindCustomerItems?CustomerName=' + CustomerName); 
}
FindCustomerItemsbasedonorderid(CustomerName,OrderId){
    return this.http.get<any[]>(api + '/api/Order/FindCustomerItems?CustomerName=' + CustomerName+'&OrderId='+OrderId); 
}
FindCustomerItemsByMenuId(CustomerName){
    return this.http.get<any[]>(api + '/api/Order/FindCustomerItemsByMenuId?CustomerName=' + CustomerName); 
}


getCRMCustomersByID(CustomerId){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Customer/CustomerCRMById?RestaurantId=' + restaurantId+'&CustomerId='+CustomerId);
}
FindFavoriteItems(CustomerId){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Customer/FindFavoriteItems?RestaurantId=' + restaurantId+'&CustomerId='+CustomerId);
}
FindLoyaltyPrograms(CustomerId){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Customer/FindLoyaltyPrograms?RestaurantId=' + restaurantId+'&CustomerId='+CustomerId);
}
FindExludeIngredients(CustomerId){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Customer/FindExludeIngredientsByCustomerId?RestaurantId=' + restaurantId+'&CustomerId='+CustomerId);
}

OrderItemQuantityReport(report){
    console.log(report)
    return this.http.post(api + '/api/Export/OrderItemQuantityReport?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId,{},  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });; 
}

RevenueReportexport(report){
    console.log(report)
    return this.http.post(api + '/api/Export/RevenueReport?restaurantIds=' + report.RestaurantIds +'&StartDate=' + report.StartDate + '&EndDate=' + report.EndDate + "&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ProvidersDeliveryIds='+report.DeliveryProvidersId,{},  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });; 
}

MoneySpentReportReport(data){
    return this.http.post(api + '/api/Export/MoneySpentReport',data,  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });;
}

DeliveryReport(data){
    return this.http.post(api + '/api/Export/DeliveryReport',data,  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });;
}

ItemsCategoryReport(XAxisType,XAxis,restaurantIds,Search,status,DeliveryProviderIds){
    return this.http.post(api + '/api/Export/ItemsCategoryReport?restaurantIds='+restaurantIds+'&XAxisType='+XAxisType+'&XAxis='+XAxis+'&Search='+Search+'&EatIn='+status.EatIn+'&TakeAway='+status.TakeAway+'&Delivered='+status.Delivered+'&DeliveryProviderIds='+DeliveryProviderIds,{},  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });;
}

SoldItemReportexport(StartDate,EndDate,CategoryId,restaurantIds){
    return this.http.post(api + '/api/Export/SoldItemReport?restaurantIds='+restaurantIds+'&StartDate='+StartDate+'&EndDate='+EndDate+'&CategoryId='+CategoryId,{},  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    })}


    SoldItemDetailsReport(StartDate,EndDate,Id,restaurantIds){
        return this.http.post(api + '/api/Export/SoldItemDetailsReport?restaurantIds='+restaurantIds+'&StartDate='+StartDate+'&EndDate='+EndDate+'&Id='+Id,{},  {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }
    
    CustomerCrmReport(body){
        return this.http.post(api + '/api/Export/CustomerCrmReport',body,  {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }
    


OrderItemDetailReport(OrderId,ItemId,IsItem) {
    return this.http.post(api + '/api/Export/OrderItemDetailReport?OrderId=' + OrderId+'&ItemId='+ItemId+'&IsItem='+IsItem,{},  {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });;
}


ExportDynamicReport(report){
    return this.http.post(api + '/api/Export/ExportDynamicReport', report, 
    {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
        responseType: 'blob'
    });
}



GetRestaurantPermissions(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Restuarant/GetRestaurantPermissions?RestaurantId=' + restaurantId);
}
findWaitingListAvailable(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Restuarant/FindWaitingListSetting?RestaurantId=' + restaurantId);
}
findTableManagementAvailable(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Restuarant/IsTableManagementAvailable?RestaurantId=' + restaurantId);
}
findIngredientsActive(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get(api + '/api/Restuarant/IsIngredientsActive?RestaurantId=' + restaurantId);
}
findPlanningAvailable(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Planning/IsPlanningAvailable?RestaurantId=' + restaurantId);
}
findOnlineReservationAvailable(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/TableManagements/IsOnlineReservationAvailable?RestaurantId=' + restaurantId);
}
IsDeliveryActive(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Restuarant/IsDeliveryActive?restaurantId=' + restaurantId);
}

findisAutomatictablecloseAvailable(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/Event/GetTablesStatusByRestaurantId?RestaurantId=' + restaurantId);
}

UpdateRestaurantPermissions(body){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.put<any[]>(api + '/api/Restuarant/UpdateRestaurantPermissions?RestaurantId=' + restaurantId,body);
}
RestaurantBookingFee(ModeOfPayment,BookingFee){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.put<any[]>(api + '/api/Restuarant/UpdateRestaurantBookingFee?Id='+ restaurantId+'&ModeOfPayment='+ModeOfPayment+'&BookingFee='+BookingFee,{});

}

GetLogData(params){
    return this.http.get<any[]>(api + '/api/Accounts/GetLogData',{params});
}


getstrategy(){
    return this.http.get<any[]>(api + '/api/Strategy/GetAllStrategies');
}
GetAllSuperManagers(){
    return this.http.get<any[]>(api + '/api/Accounts/GetAllSuperManagers');
}
GetAllorganization(){
    return this.http.get<any[]>(api + '/api/Accounts/FindAllOrganisations');
}
GetAllUsers(){
    return this.http.get<any[]>(api + '/api/Accounts/GetAllUsers');
}

GenerateUserNeWApiKey(userId){
    return this.http.put<any[]>(api + '/api/User/GenerateUserNeWApiKey?userId='+userId,{});
}

GetAllUsersbasedonID(data){
    console.log(data)
    if(data.provider==false){
        return this.http.get<any[]>(api + '/api/Accounts/GetAllUsersById?restaurantId='+data.id);
    }else if(data.provider==true){
        return this.http.get<any[]>(api + '/api/Accounts/GetAllUsersById?providerId='+data.id);
    }
}



Getweather(){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/StockHandle/WeatherResponse?RestaurantId='+restaurantId);
}


ExportLogsToCsv(params) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(api + '/api/Accounts/ExportLogsToCsv',{params}, { responseType: 'blob' });
  }


ExportLogsToJson(params) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(api + '/api/Accounts/ExportLogsToJson'+{params}, httpOptions, { responseType: 'blob' });
  }
  UpdateWaitingListSetting(IsOn){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.put<any[]>(api + '/api/Restuarant/UpdateWaitingListSetting?RestaurantId='+ restaurantId+'&IsOn='+IsOn,{});

}
UpdateWaitingListResetTime(time){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.put<any[]>(api + '/api/Restuarant/UpdateWaitingListResetTime?RestaurantId='+ restaurantId+'&time='+time,{});
}

AutomateCloseTables(time){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.put<any[]>(api + '/api/Event/AutomateCloseTables?RestaurantId='+ restaurantId+'&resetTime='+time,{});
}

UpdateTableStatus(time){
    let restaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.post<any[]>(api + '/api/Event/UpdateTableStatus?RestaurantId='+ restaurantId+'&defaultTime='+time,{});
}

DeleteStrategy(Id){
    return this.http.delete<any[]>(api + '/api/Strategy/DeleteStrategy?Id='+ Id);
}



getlevel2chartforline(XAxisType,XAxis,restaurantIds,Search,status,DeliveryProviderIds){
    return this.http.get<any[]>(api + '/api/Report/GenerateItemCategoryReport?restaurantIds='+restaurantIds+'&XAxisType='+XAxisType+'&XAxis='+XAxis+'&Search='+Search+'&EatIn='+status.EatIn+'&TakeAway='+status.TakeAway+'&Delivered='+status.Delivered+'&DeliveryProviderIds='+DeliveryProviderIds);
}

FindCustomersVisits(resturantIds,XAxisType,XAxis,status){
    return this.http.get<any[]>(api + '/api/Customer/FindCustomersVisits?RestaurantId='+resturantIds+'&XAxisType='+XAxisType+'&XAxis='+XAxis+'&EatIn='+status.EatIn+'&TakeAway='+status.TakeAway+'&Delivered='+status.Delivered);
}


GetSoldItems(StartDate,EndDate,CategoryId,restaurantIds){
    return this.http.get<any[]>(api + '/api/Order/GetSoldItems?restaurantIds='+restaurantIds+'&StartDate='+StartDate+'&EndDate='+EndDate+'&CategoryId='+CategoryId);
}


GetSoldItemDetail(StartDate,EndDate,Id,restaurantIds){
    return this.http.get<any[]>(api + '/api/Order/GetSoldItemDetail?restaurantIds='+restaurantIds+'&StartDate='+StartDate+'&EndDate='+EndDate+'&Id='+Id);
}
GetCustomerOrderDetails(StartDate,EndDate,Id,restaurantIds){
    return this.http.get<any[]>(api + '/api/Customer/GetCustomerOrderDetails?RestaurantId='+restaurantIds+'&StartDate='+StartDate+'&EndDate='+EndDate+'&Id='+Id);
}






DeleteSavedReport(id){
    return this.http.delete<any[]>(api + '/api/Report/DeleteSavedReport?Id='+ id);

}

GetTimeZone(){
    let RestaurantId = Number(localStorage.getItem('resturantId'));
    return this.http.get<any[]>(api + '/api/User/GetTimeZoneOfTheRestaurant?RestaurantId='+ RestaurantId);

}

GetApiDocumentation(restaurant,id){
    if(restaurant==true){
        return this.http.get<any[]>(api + '/api/Broker/GetApiDocumentation?restaurant='+ restaurant+'&restaurantId='+id);
    }else{
        return this.http.get<any[]>(api + '/api/Broker/GetApiDocumentation?restaurant='+ restaurant+'&providerId='+id);
    }

}

UpdateApiPermission(data){
    return this.http.post<any[]>(api + '/api/Broker/UpdateApiPermission',data);
}

}