import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private http: HttpClient) { }
    // ----------- 
    generateOrderReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateOrderReport?restaurantIds=' + report.restaurantIds +'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType + '&YDisplayType=' + report.yDisplayType + '&IsEmployee=' + report.isEmployee +
         '&PaymentChartType=' + report.paymentChartType+"&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ChartType='+report.ChartType+'&DeliveryProvidersId='+report.DeliveryProvidersId);
    }
    generateTodayReport(data): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateTodayReport?restaurantIds=' + data.restaurantIds+'&StartDate='+data.StartDate+'&EndDate='+data.EndDate+"&EatIn="+data.eatIn+"&takeAway="+data.takeAway+"&delivered="+data.delivered+"&xDisplayType="+data.xDisplayType+'&ProvidersDeliveryIds='+data.DeliveryProvidersId);
    }
    generatePaymentReport(report: any,status): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GeneratePaymentReport?restaurantIds=' + report.restaurantIds + '&XAxis=' + report.XAxis + '&XAxisType=' + report.XAxisType + '&IsEmployee=' + report.isEmployee + '&PaymentChartType=' + report.paymentChartType+'&EatIn='+status.EatIn+'&TakeAway='+status.TakeAway+'&Delivered='+status.Delivered);
    }
    

    generateMarginEstimationReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateMarginEstimationReport?RestaurantIds=' + report.restaurantIds + '&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType+'&EatIn='+report.EatIn+'&TakeAway='+report.TakeAway+'&Delivered='+report.Delivered+'&ChartType='+report.ChartType);
    }
    generateCustomerReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateCustomerReport?restaurantIds=' + report.restaurantIds + '&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType+"&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ChartType='+report.ChartType+'&DeliveryProvidersId='+report.DeliveryProvidersId);
    }
    pTGenerateMarginEstimationReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/PTGenerateMarginEstimationReport?StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType);
    }
    
    
    generateItemReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateItemReport?restaurantIds=' + report.restaurantIds +'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType + '&YDisplayType=' + report.yDisplayType +"&EatIn="+report.EatIn+"&TakeAway="+report.TakeAway+"&Delivered="+report.Delivered+'&ChartType='+report.ChartType+'&DeliveryProvidersId='+report.DeliveryProvidersId);
    } 

    GenerateStaticOrderReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateStaticOrderReport?restaurantIds=' + report.restaurantIds +'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType + '&YDisplayType=' + report.yDisplayType + '&IsEmployee=' + report.isEmployee + '&PaymentChartType=' + report.paymentChartType);
    }
    generateReportForPayment(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateReportForPayment?RestaurantId=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType);
    }

    GenerateMoneySpendandDeliveryItemReportlevel3(report: any): Observable<any> {
        console.log(report)
        return this.http.get<any>(api + '/api/Report/GenerateMoneySpendandDeliveryItemReport?RestaurantIds=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType+'&Levels=' + report.Levels+'&IsDelivery=' + report.IsDelivery+'&productId='+report.productId);
    }
    GenerateMoneySpendandDeliveryItemReport(report: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        if(restaurantId==0||restaurantId==null){
            return this.http.get<any>(api + '/api/Report/GenerateMoneySpendandDeliveryItemReportForProvider?RestaurantIds=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType+'&Levels=' + report.Levels+'&IsDelivery=' + report.IsDelivery);
        }else{
            return this.http.get<any>(api + '/api/Report/GenerateMoneySpendandDeliveryItemReport?RestaurantIds=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType+'&Levels=' + report.Levels+'&IsDelivery=' + report.IsDelivery);
        }
    }
    GenerateMoneySpendandDeliveryItemReportleve2(report: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        if(restaurantId==0||restaurantId==null){
        return this.http.get<any>(api + '/api/Report/GenerateMoneySpendandDeliveryItemReportForProvider?RestaurantIds=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType+'&Levels=' + report.Levels+'&IsDelivery=' + report.IsDelivery+'&CategoryOfProductId='+report.CategoryOfProductId);
        }else{
            return this.http.get<any>(api + '/api/Report/GenerateMoneySpendandDeliveryItemReport?RestaurantIds=' + report.RestaurantIds+'&StartDate=' + report.startDate + '&EndDate=' + report.endDate + '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType+'&Levels=' + report.Levels+'&IsDelivery=' + report.IsDelivery+'&CategoryOfProductId='+report.CategoryOfProductId);
        }
    }

    generateReportForDeliveryItems(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateReportForDeliveryItems?RestaurantId=' + report.RestaurantIds +'&StartDate=' + report.startDate + '&EndDate=' + report.endDate+ '&XDisplayType=' + report.xDisplayType +'&ChartType=' + report.ChartType);
    }
    
    generateProviderPaymentReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateProviderPaymentReport?StartDate=' + report.startDate + '&EndDate=' + report.endDate  + '&XDisplayType=' + report.xDisplayType + '&IsProduct=' + report.isProduct);
    }

    generateProviderDeliveryReport(report: any): Observable<any> {
        return this.http.get<any>(api + '/api/Report/GenerateProviderDeliveryReport?StartDate=' + report.startDate + '&EndDate=' + report.endDate);
    }


    FindCustomerOrders(CustomerId,PageNumber,PageSize): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/Order/FindCustomerOrders?RestaurantId=' + restaurantId + '&CustomerId=' +CustomerId+'&PageNumber='+PageNumber+'&PageSize='+PageSize);
    }

    FindCustomerLastVisitedOrders(CustomerId): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/Order/FindCustomerLastVisitedOrders?RestaurantId=' + restaurantId + '&CustomerId=' +CustomerId);
    }

    FindCustomerOnlineOrders(CustomerId,PageNumber,PageSize): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/Order/FindCustomerOnlineOrders?RestaurantId=' + restaurantId + '&CustomerId=' +CustomerId+'&PageNumber='+PageNumber+'&PageSize='+PageSize);
    }

    



    // ------------ 
    ExportReportForGlobalReport(reqData): Observable<any> {
        return this.http.post(api + '/api/Export/ExportReportForGlobalReport', reqData, 
        {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }

    ItemsGraphReport(reqData): Observable<any> {
        return this.http.post(api + '/api/Export/ItemsGraphReport', reqData, 
        {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }

    SalesReport(reqData): Observable<any> {
        return this.http.post(api + '/api/Export/SalesReport', reqData, 
        {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }

    ItemsReport(reqData): Observable<any> {
        return this.http.post(api + '/api/Export/ItemsReport', reqData, 
        {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }
 
    AddWebserviceMapping(report: any): Observable<any> {
        return this.http.post<any>(api + '/api/Export/AddWebserviceMapping',report);
    }


    GenerateDynamicReport(data:any){
        return this.http.get(api + '/api/Report/GenerateDynamicReport?'+ data)
    }

    CreateSavedReport(body){
        return this.http.post<any>(api + '/api/Report/CreateSavedReport',body);
    }

    GetSavedReports(){
        return this.http.get(api + '/api/Report/GetSavedReport')

    }
    GetSavedReportById(id){
        return this.http.get(api + '/api/Report/GetSavedReportById?id='+ id)

    }


    GetDeliveryProviders(IsExternal,IsInternal){
        return this.http.get(api + '/api/Order/GetDeliveryProviders?IsExternal='+ IsExternal+'&IsInternal='+IsInternal)

    }
    GetOnlineReservationproviders(){
        return this.http.get(api + '/api/Order/GetAllOnlineReservationProviders')

    }
    ExportDynamicReport(report){
        return this.http.post(api + '/api/Export/ExportDynamicReport', report, 
        {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/zip' },
            responseType: 'blob'
        });
    }
    
    


    
}
