import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-conformation',
  templateUrl: './conformation.component.html',
  styleUrls: ['./conformation.component.scss'],
})
export class ConformationComponent implements OnInit {
  screentype=''
  form: FormGroup;
  todaydate = new Date();
  deleteType='1'
  constructor(
    private dialogRef: MatDialogRef<ConformationComponent>,

  ) { }

  ngOnInit() {}



  onSubmit2(){
    this.dialogRef.close(this.deleteType)
   }
}
