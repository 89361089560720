import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ingredient } from '../../models/ingredient.model';
import { ProviderProduct } from '../../models/provider-product.model';
import { Provider } from '../../models/provider.model';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class ProviderProductService {
    private loadData = new Subject<any>();
    data = this.loadData.asObservable();
    
    constructor(private http: HttpClient) { }
    loadingData(type) {
        this.loadData.next(type);
      }
     
    getProviderProductsService(NumberOfRecord,PageNumber): Observable<ProviderProduct[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<ProviderProduct[]>(api + '/api/ProviderProduct/FindAllProducts', { params })
        .pipe(
            map(response => response["data"])
        );
    }
    getAllingredients(){
        let  restaurantId =sessionStorage.getItem('resturantId')
        return this.http.get(api + '/api/Ingredients/GetIngredientsForDropDown?restaurantId='+restaurantId)
    }
    GetMenuForDropDown(){
        let  restaurantId =sessionStorage.getItem('resturantId')
        return this.http.get(api + '/api/Menu/GetMenuForDropDown?restaurantId='+restaurantId)
    }


    FindAllItems(PageNumber,NumberOfRecord) {
        const params = new HttpParams()
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        return this.http.get(api + 'api/Item/FindAllItems', { params })
    }






   
    

    Deliveryireportdropdown(Ids,IsRestaurant){
        const params = new HttpParams()
        .append('Ids', Ids)
        .append('IsRestaurant', IsRestaurant)
        return this.http.get<ProviderProduct[]>(api + '/api/Deal/RestaurantProviderDealDropdown', { params })
    }
    paymentreportdropdown(Ids,IsRestaurant){
        const params = new HttpParams()
        .append('Ids', Ids)
        .append('IsRestaurant', IsRestaurant)
        return this.http.get<ProviderProduct[]>(api + '/api/Pay/RestaurantproviderPaymentDropdown', { params })
    }
    GetProductsForDropDown(){
        return this.http.get<ProviderProduct[]>(api + '/api/ProviderProduct/GetProductsForDropDown')
    }

    getProviderProductsWithPagination(Search:any,numberOfRecord: any, page: any): Observable<ProviderProduct[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', numberOfRecord)
        .append('PageNumber', page)
        .append('Search', Search)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<ProviderProduct[]>(api + '/api/ProviderProduct/FindAllProducts', { params })
        .pipe(
            map(response => response["data"])
        );
    }
    FindProgressDealsWithpagination(numberOfRecord: any, page: any): Observable<any[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', numberOfRecord)
        .append('PageNumber', page)
        .append('RestaurantId', sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Deal/FindProgressDeals' , { params })
        .pipe(
            map(response => response["data"])
        );;
    }
    FindFavoritesProvidersWithpagination(numberOfRecord: any, page: any): Observable<any[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', numberOfRecord)
        .append('PageNumber', page)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/Provider/FindFavoritesProviders', { params }).pipe(
            map(response => response["data"])
        );;
    }

    FindOfferBucketsIngredients(RestaurantId){
        return this.http.get<any[]>(api + '/api/OfferBucket/FindOfferBucketsIngredients' + '?RestaurantId='+ RestaurantId );

    }

    getAllProviderProductsService(page, limit): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ProviderProduct/FindAllProducts' + '?PageNumber='+ page + '&NumberOfRecord='+ limit);
    }
    FindProvidersByProductName(productName): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/Provider/FindProvidersByProductName' + '?ProductName='+ productName);
    }
    FindProvidersByProductName1(Search,productName, page, limit): Observable<any[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Provider/FindProvidersByProductName' + '?ProductName='+ productName +'&PageNumber=' + page + '&NumberOfRecord='+ limit + '&RestaurantId=' + restaurantId+'&Search='+Search);
    }
    FindFavoritesProviders(NumberOfRecord,PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/Provider/FindFavoritesProviders', { params }).pipe(
            map(response => response["data"])
        );;
    }
    findProductById(productId: string): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ProviderProduct/FindProductById?ProductId=' + productId);
    }
    FindProductHistory(NumberOfRecord,PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/ProviderProduct/FindProductHistory',{params});
    }
    findSelectedProductHistory(productId: string,NumberOfRecord, PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('ProductId', productId)
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/ProviderProduct/FindSelectedProductHistory', { params })
        .pipe(
            map(response => response["data"])
        );;
    }
    FindDealComment(dealId): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/Comment/FindDealComment?DealId=' + dealId);
    }
    FindDealTracking(dealTrackingId): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/Deal/FindDealTracking?TrackingId=' + dealTrackingId);
    }
    FindProgressDeals(NumberOfRecord,PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Deal/FindProgressDeals' , { params })
        .pipe(
            map(response => response["data"])
        );;
    }

    FindProductHistoryByProviderId(ProviderId ,NumberOfRecord, PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('ProviderId', ProviderId)
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/ProviderProduct/FindProductHistoryByProviderId', {params});
    }

    FilterProviders(searchQuery): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/Provider/FilterProviders?' + searchQuery).pipe(
            map(response => response["data"])
        );;
    }
    AdvanceFilterForInProgress(data): Observable<any[]> {  
        let params = new HttpParams();
        Object.keys(data).forEach((key) => {
            if (data[key] != null) { // Check for null or undefined
                params = params.append(key, data[key]);
            }
        });
        return this.http.get<any[]>(api + '/api/Deal/AdvanceFilterForInProgress', {params});
    }
    AdvanceFilterForHistory(data): Observable<any[]> {
        let params = new HttpParams();
        Object.keys(data).forEach((key) => {
            if (data[key] != null) { // Check for null or undefined
                params = params.append(key, data[key]);
            }
        });
        return this.http.get<any[]>(api + '/api/ProviderProduct/AdvanceFilterForHistory', {params});
    }

    FindProfile(ProviderId,NumberOfRecord,PageNumber): Observable<any[]> {
        const params = new HttpParams()
        .append('ProviderId', ProviderId)
        .append('NumberOfRecord', NumberOfRecord)
        .append('PageNumber', PageNumber)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/Provider/FindProfile', {params});
    }
    FindProviderProfileDeals(ProviderId: string,NumberOfRecord,PageNumber): Observable<any[]> {
        const params = new HttpParams()
            .append('ProviderId', ProviderId)
            .append('NumberOfRecord', NumberOfRecord)
            .append('PageNumber', PageNumber)
            .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<any[]>(api + '/api/Provider/FindProviderProfileDeals', { params }).pipe(
            map(response => response)
        );
    }
    activateOrDeactivateProviders(provider, productName: string): Observable<any> {
        return this.http.post(api + '/api/Provider/ActivateOrDeactivateProviders?RestaurantId=' + sessionStorage.getItem('resturantId') +'&ProviderId=' + provider.providerId +'&IsActivate=' + provider.isActivated +'&ProductName=' + productName, {});
    }
    addOrRemoveFavoriteProvider(providerId): Observable<any> {
        return this.http.post(api + '/api/Provider/AddOrRemoveFavoriteProvider?RestaurantId=' + sessionStorage.getItem('resturantId') +'&ProviderId=' + providerId  +'&IsRestaurant=' + true, {});
    }
    
    updateProductsInCart( cartId: number, quantity: number): Observable<any> {
        return this.http.put(api + '/api/Cart/UpdateProductsInCart?CartId=' + cartId + '&Quantity=' + quantity, {});
    }
    removeProductFromCart(id: number): Observable<any> {
        return this.http.delete(api + '/api/Cart/RemoveProductFromCart?CartId=' + id);
    }

    CreateFilterMenu(filter,CustomerDetails): Observable<any> {
        return this.http.post(api + '/api/Menu/FilterMenu?RestaurantId=' + sessionStorage.getItem('resturantId')+'&CustomerId='+CustomerDetails.id+'&CustomerName='+CustomerDetails.name, filter);
    }

    GetMenuKeywords(): Observable<any> {
        return this.http.get(api + '/api/Menu/GetMenuKeywords?RestaurantId=' + sessionStorage.getItem('resturantId'));
    }
    ItemKeywordDropdown(): Observable<any> {
        return this.http.get(api + '/api/Item/ItemKeywordDropdown?RestaurantId=' + sessionStorage.getItem('resturantId'));
    }

    CreateChat(data): Observable<any> {
        return this.http.post(api + '/api/Comment/CreateChat', data);
    }
    GetAllCharts(dealId): Observable<any> {
        return this.http.get(api + '/api/Comment/GetChatById?dealId='+dealId);
    }


    CreateFeedBacks(data): Observable<any> {
        return this.http.post(api + '/api/Comment/CreateFeedBacks', data);
    }
}