import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/shared/services/api/user.service';

@Component({
  selector: 'app-add-new-tag',
  templateUrl: './add-new-tag.component.html',
  styleUrls: ['./add-new-tag.component.scss'],
})
export class AddNewTagComponent implements OnInit {
  @Input()ScreenType: string;
  selectedradioval=null;
  selectednumeralval=null;
  tagform: FormGroup;
  viewtagmodel :any= {};
  showfreefeild!: boolean;
  tagViewModels:any = [];
  showcheckbox!: boolean;
  dropdownValues = [1, 2, 3, 4, 5]
  tags: any;
  tagType: any
  selectedvalue: any;
  value!: number;
  i = 1;
  dropdownValue :any= []
  constructor(
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private userService: UserService,
  ) { 
    this.tagform = this.formBuilder.group({
      TagViewModels: [[]],
      tagType: [''],
      title: [''],
      tagdropdown: [''],
      tagTableId:[0],
      ischecked: [''],
      numeralType: [''],
      freefeildTitle: ['', Validators.pattern('^[a-zA-Z \-\']+')],
      freefeildnumber: [''],
      fieldName: [''],
      fieldvalue: [''],
      dropdownValue: [''],
      freefeildvalue: [''],
      dropdowntype: [''],
      dropdown: [''],
      fieldType:[''],
      Mandatory:[false],
      Adddropdownvalue: this.formBuilder.array([
        this.createBody()
      ])
    });
  }

  ngOnInit(): void {
    console.log(this.ScreenType)
  }


  createBody(): FormGroup {
    return this.formBuilder.group({
      dropdownvalue: ['']
    })
  }
  addcreateBody() {
    this.getfileBody().push(this.createBody())
  }

  removeConditionalBody(i: number) {
    this.getfileBody().removeAt(i);
  }
  getfileBody() {
    return this.tagform.get('Adddropdownvalue') as FormArray;
  }

  close() {
    this.modalCtrl.dismiss(true)
  }
  checkChanges() {
    this.tagform.get('numeralType')?.valueChanges.subscribe(val => {
      this.selectednumeralval = val;
    });
  }
  getdropdown(i:number) {
    this.selectedvalue = i;
  }
  onChanges() {
    this.tagform.get('tagType')?.valueChanges.subscribe(val => {
      this.selectedradioval = val;
    });
  }


 async onSubmit(){
  const data=[{
    fieldName:this.tagform.value.title,
    fieldType:this.tagform.value.tagType,
    isMandatory:this.tagform.value.Mandatory,
    dropdownValues:'',
    restaurantId:sessionStorage.getItem('resturantId')
  }]
  try {
    const result =await this.userService.addtagcolum(this.ScreenType,data)
    this.modalCtrl.dismiss(this.tagform.value) 
  } catch (error) {
    
  }
  }
}
